<template>
    <div>
        <div class="py-3 mx-3 mx-lg-4 mx-xl-5">
            <h2 class="text-left mt-0 mt-md-4">{{card.name}}</h2>
            <hr>
            <div class="d-flex flex-column flex-md-row">
                <div class="text-left">
                    <div class="d-md-flex text-center flex-row align-items-stretch">
                        <Image :src="card.assets[0].gameAbsolutePath" :width="280" :height="430" />
                    </div>
                    <VenatusAd class="mt-4 mb-n4 pe-3" v-if="$store.state.winWidth > 768" :height="800" />
                    <VenatusAd class="mt-4 mb-n4 pe-3" v-else :height="150" />
                </div>
                <div class="mt-0 mb-3 ms-md-4 mb-md-4 text-left flex-fill">
                    <div class="fst-italic px-4 py-3 d-flex flex-column w-100">
                        <!-- <div>{{card.flavorText}}</div>
                        <hr>
                        <div class="mt-auto">
                            Type: {{getCardType(card)}}
                        </div>
                        <div>Artist: {{card.artistName}}</div> -->
                        <div>
                            <h6 class="fw-bold">Description</h6>
                            <p class="mx-3">{{card.descriptionRaw}}</p>
                            <hr>
                        </div>
                        <div v-if="card.levelupDescriptionRaw">
                            <h6 class="fw-bold">Level Up</h6>
                            <p class="mx-3">{{card.levelupDescriptionRaw}}</p>
                            <hr>
                        </div>
                        <div>
                            <h6 class="fw-bold">Stats</h6>
                            <p class="mx-3">Attack: {{card.attack}} | Health: {{card.health}} | Cost: {{card.cost}}</p>
                            <hr>
                        </div>
                        <div v-if="card.keywords.length">
                            <h6 class="fw-bold">Keywords</h6>
                            <p class="mx-3">
                                <span v-for="(keyword, index) in card.keywordRefs" :key="card.code + keyword" class="me-3">
                                    <Image :src="$cdn.url('lor', ['keywords', keyword], 'png')" :size="30"/> {{card.keywords[index]}}
                                </span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <Loading class="mt-n5" v-if="loading">LOADING</Loading>
                    <Error class="mt-n5" v-else-if="error">{{errorDetail}}</Error>
                    <div v-else class="px-3 px-md-4">
                        <h4 class="mt-2 mb-4">Master Decks</h4>
                        <div v-if="proDecks.length == 0">
                            <Error class="mt-n5">Looks like there is no master decks for this card.</Error>
                        </div>
                        <div v-for="(proDeck, ind) in proDecks" :key="'prodeck-' + ind" class="mt-2">
                            <div
                                :class="[
                                    selectedProDeck == ind ? 'top-bg': 'win-bg',
                                    'px-3 px-lg-4 py-3 d-flex justify-content-between align-items-center cursor-pointer'
                                ]"
                                @click="selectedProDeck != ind ? selectedProDeck = ind: selectedProDeck = null"
                            >
                                <div class="text-lightgray capfirst">{{proDeck.region}}</div>
                                <div>{{proDeck.playerName}}</div>
                                <div>Rank: {{proDeck.rank + 1}}</div>
                            </div>
                            <transition name="y-open" mode="in-out">
                                <div v-if="selectedProDeck == ind || $route.query.rendertron" class="overflow-y-hidden py-4 px-3 px-sm-4 null-detail-bg">
                                    <div class="cards-container" :style="{maxHeight: $store.state.winWidth < 992 ? '280px': null}">
                                        <div class="mb-3 row" v-for="(cards, cardType) in parseCodeTypes(proDeck.deckDict)" :key="'prodeck-' + ind + cardType">
                                            <span class="">{{cardType}}s:</span>
                                            <div
                                                class="mt-2 px-4 col-12 col-lg-6 col-xxl-4"
                                                v-for="card in cards"
                                                :key="'prodeck-' + ind + cardType + card.code"
                                            >
                                                <Card
                                                    :code="card.code"
                                                    :count="card.count"
                                                    height="35px"
                                                    width="100%"
                                                    variant="banner"
                                                    :link="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="my-3 text-left">Deck Code:</h6>
                                    <div class="text-nowrap">
                                        <Input
                                            class="cursor-pointer"
                                            :disabled="true"
                                            width="calc(100% - 55px)"
                                            v-model="proDeck.deckCode"
                                            :borderRadiuses="['20px', 0, 0, '20px']"
                                        />
                                        <Button
                                            class=""
                                            :borderRadiuses="[0, '20px', '20px', 0]"
                                            v-clipboard:copy="proDeck.deckCode"
                                            v-tooltip="'Copy to Clipboard'"
                                        ><Icon name="clipboard-plus" class="me-1" /></Button>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mx-3 mx-lg-4 mx-xl-5" v-if="subcards.length">
            <h2 class="text-left">Related Cards</h2>
            <hr>
        </div>
        <div class="row py-3 mx-3 mx-lg-4 mx-xl-5">
            <div class="col-12 col-md-6 mt-0 mt-md-4 text-left" v-for="card in subcards" :key="card.cardCode">
                <router-link :to="`/lor/cards/${card.cardCode}/${card.name.replace(/[^\w ]+/g,'').replace(/ +/g,'-')}`">
                    <h3>{{card.name}} <Icon name="link-45deg" :size="30" /></h3>
                </router-link>
                <hr>
                <div class="d-md-flex text-center flex-row align-items-stretch">
                    <Image
                        :src="card.assets[0].gameAbsolutePath"
                        :width="280"
                        :height="430"
                        :to="`/lor/cards/${card.cardCode}/${card.name.replace(/[^\w ]+/g,'').replace(/ +/g,'-')}`"
                    />
                    <div class="fst-italic px-4 py-3 d-flex flex-column w-100">
                        <!-- <div>{{card.flavorText}}</div>
                        <hr>
                        <div class="mt-auto">
                            Type: {{getCardType(card)}}
                        </div>
                        <div>Artist: {{card.artistName}}</div> -->
                        <div>
                            <h6 class="fw-bold">Description</h6>
                            <p class="mx-3">{{card.descriptionRaw}}</p>
                            <hr>
                        </div>
                        <div v-if="card.levelupDescriptionRaw">
                            <h6 class="fw-bold">Level Up</h6>
                            <p class="mx-3">{{card.levelupDescriptionRaw}}</p>
                            <hr>
                        </div>
                        <div>
                            <h6 class="fw-bold">Stats</h6>
                            <p class="mx-3">Attack: {{card.attack}} | Health: {{card.health}} | Cost: {{card.cost}}</p>
                            <hr>
                        </div>
                        <div v-if="card.keywords.length">
                            <h6 class="fw-bold">Keywords</h6>
                            <p class="mx-3">
                                <span v-for="(keyword, index) in card.keywordRefs" :key="card.code + keyword">
                                    <span v-if="!card.keywords[index].includes('Missing')">
                                        <Image :src="$cdn.url('lor', ['keywords', keyword], 'png')" :size="30"/> {{card.keywords[index]}}
                                    </span>
                                </span>
                            </p>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/scss/transitions.scss";

.cards-container {
    overflow-y: scroll;
    overflow-x: hidden;
}

@include y-open(300px);
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Image from '@/components/Image.vue'
import Loading from '@/components/Loading.vue'
import Input from '@/components/Input.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import Error from '@/components/Error.vue'
import Card from '@/components/lor/Card.vue'

@Options({components: {Image, Loading, Error, Card, Input, Button, Icon}})
export default class CardView extends Vue {

    card: Record<string, any> = {}
    subcards: Array<Record<string, any>> = []
    loading = true
    proDecks: Array<Record<string, any>> = []
    selectedProDeck = 0
    error = false
    errorDetail = ""

    async created (): Promise<void> {
        const card = this.$asset.lor.cards[this.$route.params.code.toString()]
        this.card = card
        for (const subcard of card.associatedCardRefs)
            this.subcards.push(this.$asset.lor.cards[subcard])
        this.$meta.use({
            title: `${card.name} Card Info & Master Decks | StatHub.gg LoR`,
            og: {
                image: card.assets[0].gameAbsolutePath,
                description: `${card.name} info and master decks | ${card.descriptionRaw} -- ${card.flavorText}`
            }
        })
        try {
            this.proDecks = await this.$api.get<any>('lor', ['cards', 'master-decks', this.card.cardCode])
        } catch (err: any) {
            this.error = true
            this.errorDetail = err.response.data.detail
        }
        this.loading = false
    }

    parseCodeTypes(cards: Array<Record<string, any>>): Record<string, Array<Record<string, any>>> {
        const cardTypes: Record<string, any> = {}

        for (const card of cards) {
            const cardObj = this.$asset.lor.cards[card.code]
            const cardType = this.getCardType(cardObj)

            if (cardTypes[cardType] == undefined)
                cardTypes[cardType] = []

            cardTypes[cardType].push(card)
        }

        return cardTypes
    }

    getCardType(card: Record<string, any>): string {
        if (card.type == 'Unit' && card.supertype == 'Champion')
            return 'Champion'
        else
            return card.type
    }

}
</script>
