
import { Options, Vue } from 'vue-class-component'
import Image from '@/components/Image.vue'
import Loading from '@/components/Loading.vue'
import Input from '@/components/Input.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import Error from '@/components/Error.vue'
import Card from '@/components/lor/Card.vue'

@Options({components: {Image, Loading, Error, Card, Input, Button, Icon}})
export default class CardView extends Vue {

    card: Record<string, any> = {}
    subcards: Array<Record<string, any>> = []
    loading = true
    proDecks: Array<Record<string, any>> = []
    selectedProDeck = 0
    error = false
    errorDetail = ""

    async created (): Promise<void> {
        const card = this.$asset.lor.cards[this.$route.params.code.toString()]
        this.card = card
        for (const subcard of card.associatedCardRefs)
            this.subcards.push(this.$asset.lor.cards[subcard])
        this.$meta.use({
            title: `${card.name} Card Info & Master Decks | StatHub.gg LoR`,
            og: {
                image: card.assets[0].gameAbsolutePath,
                description: `${card.name} info and master decks | ${card.descriptionRaw} -- ${card.flavorText}`
            }
        })
        try {
            this.proDecks = await this.$api.get<any>('lor', ['cards', 'master-decks', this.card.cardCode])
        } catch (err: any) {
            this.error = true
            this.errorDetail = err.response.data.detail
        }
        this.loading = false
    }

    parseCodeTypes(cards: Array<Record<string, any>>): Record<string, Array<Record<string, any>>> {
        const cardTypes: Record<string, any> = {}

        for (const card of cards) {
            const cardObj = this.$asset.lor.cards[card.code]
            const cardType = this.getCardType(cardObj)

            if (cardTypes[cardType] == undefined)
                cardTypes[cardType] = []

            cardTypes[cardType].push(card)
        }

        return cardTypes
    }

    getCardType(card: Record<string, any>): string {
        if (card.type == 'Unit' && card.supertype == 'Champion')
            return 'Champion'
        else
            return card.type
    }

}
